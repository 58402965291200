/* eslint-disable no-nested-ternary */
import React from 'react';
import { NavLink } from 'react-router-dom';
import './NavBar.css';

const NavBar: React.FC = () => {
  return (
    <nav className='navbar'>
      <div className='navbar-content'>
        <div className='navbar-menu'>
          <div className='center-link'>
            <NavLink
              className='extra-link body semi-bold'
              style={({ isActive }) => ({ color: isActive ? '#154e01' : '#858d95' })}
              to='/'
            >
              Home
            </NavLink>
            <NavLink
              className='extra-link body semi-bold'
              style={({ isActive }) => ({ color: isActive ? '#154e01' : '#858d95' })}
              to='/more-recipes'
            >
              More Recipes
            </NavLink>
            <NavLink
              className='extra-link body semi-bold'
              style={({ isActive }) => ({ color: isActive ? '#154e01' : '#858d95' })}
              to='/about'
            >
              About
            </NavLink>
            <NavLink
              className='extra-link body semi-bold'
              style={({ isActive }) => ({ color: isActive ? '#154e01' : '#858d95' })}
              to='/contact-us'
            >
              Contact Us
            </NavLink>
          </div>
        </div>

        {/* <div className='navbar-dropdown'>
          <button>Avatar</button>
        </div> */}
      </div>
    </nav>
  );
};

export default NavBar;