import React from 'react';
// import { Link } from 'react-router-dom';
import './BuyRecipeBundle.css';

const BuyRecipeBundle: React.FC = () => {
  return (
    <div className="bundles">
      <div className="bundle-list">
        <div className="bundle-item">
          <h2 className="bundle-label">50 Italian Recipes Bundle</h2>
          <p className="bundle-desc">
            Discover a collection of 50 Italian-inspired recipes, 
            thoughtfully crafted for those managing PCOS. This 
            bundle offers a delicious blend of traditional Italian 
            flavors with a focus on balanced nutrition, low glycemic 
            ingredients, and hormone-friendly options. Enjoy wholesome 
            meals that support your health without compromising on taste
          </p>
          <button className="bundle-btn" disabled={true}>
            Coming Soon
          </button>
        </div>

        <div className="bundle-item">
          <h2 className="bundle-label">50 Korean Recipes Bundle</h2>
          <p className="bundle-desc">
            Explore a bundle of 50 Korean-inspired recipes, specially 
            designed to be PCOS-friendly. This collection blends the vibrant 
            and bold flavors of Korean cuisine with ingredients that support 
            hormonal balance and overall wellness. Enjoy tasty, nutrient-rich 
            dishes that are perfect for managing PCOS while indulging in your 
            love for Korean food
          </p>
          <button className="bundle-btn" disabled={true}>
            Coming Soon
          </button>
        </div>

        <div className="bundle-item">
          <h2 className="bundle-label">50 Mediterranean Recipes Bundle</h2>
          <p className="bundle-desc">
            Indulge in a bundle of 50 Mediterranean-inspired recipes, carefully 
            curated for those managing PCOS. This collection features 
            nutrient-dense dishes rich in healthy fats, lean proteins, and 
            fresh produce, all designed to support hormonal balance and overall 
            well-being. Enjoy the vibrant flavors of the Mediterranean while 
            nourishing your body with PCOS-friendly ingredients
          </p>
          <button className="bundle-btn" disabled={true}>
            Coming Soon
          </button>
        </div>

      </div>
    </div>
  );
};

export default BuyRecipeBundle;