import React from 'react';

const About: React.FC = () => {
  return (
    <div className="bundles">
    </div>
  )
};

export default About;
