import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Recipes from '../pages/Recipes';
import BuyRecipeBundle from '../pages/BuyRecipeBundle';
import About from '../pages/About';
import ContactUs from '../pages/ContactUs';
import DNE from '../pages/DNE'

export const Routers: React.FC = () => (
    <Routes>
      <Route path='' element={<Recipes />} />
      <Route path='more-recipes' element={<BuyRecipeBundle />} />
      <Route path='about' element={<About />} />
      <Route path='contact-us' element={<ContactUs />} />

      {/* Page not found*/}
      <Route path='*' element={<DNE />} />
    </Routes>
);
