import React from 'react';
import { Col, Layout, Typography, Button } from 'antd';
import { Link } from 'react-router-dom';
import './DNE.css';

const { Title, Text } = Typography;

const DNE: React.FC = () => {
  return (
    <Layout className='dne-page-outer-container'>
      <Col className='dne-page-container'>
        <Title level={3} className='dne-page-title'>
          Page does not exist
        </Title>
        <Text className='dne-page-subtitle'>Sorry, we can&apos;t find the page you&apos;re looking for</Text>

        <Link to='/'>
          <Button size='large' className='dne-page-go-home-button-container' shape='round'>
            Homepage
          </Button>
        </Link>
      </Col>
    </Layout>
  );
};

export default DNE;