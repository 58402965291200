import React from 'react';
import './App.css';
// import data from './assets/data.json'; // Import your JSON data here
// import RecipeDetail from './components/RecipeDetail';
// import { Recipes } from './pages/Recipes';
import { Routers } from './components/Routes';
import NavBar from './components/NavBar';

function App() {
  return (
    <React.Fragment>
      <NavBar />
      <Routers />
    </React.Fragment>
  );
}

export default App;
