import React, { useState, useEffect, ChangeEvent } from 'react';
import './Recipes.css';
import data from '../assets/data.json'; // Import your JSON data here
import RecipeDetail from '../components/RecipeDetail';
// const { Item, ItemGroup } = Menu;
import { DataItem } from '../services/model'

 const Recipes: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [filteredData, setFilteredData] = useState<DataItem[]>(data);
  const [selectedItem, setSelectedItem] = useState<DataItem | null>(null);
  const uniqueTags = Array.from(new Set(data.flatMap(item => item.tags)));
  const [collapsible, setCollapsible] = useState(false);

  useEffect(() => {
    setFilteredData(
      data.filter((item: DataItem) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
        (selectedTags.length === 0 || selectedTags.every(tag => item.tags.includes(tag)))
      )
    );
  }, [searchTerm, selectedTags]);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleTagChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const selectedOptions = Array.from(e.target.selectedOptions, option => option.value);
    setSelectedTags(selectedOptions);
  };

  const handleItemClick = (item: DataItem) => {
    setSelectedItem(item);
  };

  const handleBack = () => {
    setSelectedItem(null);
  };

  // const uniqueTags = Array.from(new Set(data.flatMap(item => item.tags)));

  return (
    <div className="Recipes">
      {selectedItem ? (
        <RecipeDetail item={selectedItem} onBack={handleBack} />
      ) : (
        <>
          <div className="gallery-ctn">
            <div className="search-bar">
              <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={handleSearch}
              />
            </div>
            <div className="collapsible">
              <button onClick={() => setCollapsible(!collapsible)}>
                {collapsible ? 'Close filter' : 'Filter by tags'}
              </button>
              {collapsible && (
                <select multiple value={selectedTags} onChange={handleTagChange}>
                  {uniqueTags.map(tag => (
                    <option key={tag} value={tag}>{tag}</option>
                  ))}
                </select>
              )}
              {collapsible && (
                <button onClick={() => setSelectedTags([])}>Clear</button>
              )}
            </div>

            <div className="data-list">
              {filteredData.map(item => (
                <button key={item.id} className="data-item" onClick={() => handleItemClick(item)}>
                  <img src={item.img} alt={item.name}/>
                  <h2>{item.name}</h2>
                  <p>{item.description}</p>
                  <div className="tags">
                    {item.tags.map(tag => (
                      <div className="tag">{tag}</div>

                      // <span key={tag} className="tag">{tag}, </span>
                    ))}
                  </div>
                </button>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Recipes;
